import { Button, Card, Delete, Title } from 'rbx';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { css } from 'emotion';
import { format } from 'date-fns';
import useUser from '../hooks/useUser';
import useSubscriptions from '../hooks/useSubscriptions';
import MagicLinkCreator from '../pages/account/components/MagicLinkCreator';
import DeleteAccount from '../pages/account/components/DeleteAccount';
import { UserRoleTag } from './';

function UserCard({ photo }) {
  const { user, setUser, updateUserField, getUserById } = useUser();
  const {
    subscription,
    isLoading: isSubscriptionDetailLoading,
  } = useSubscriptions();
  const [loading, setLoading] = useState(false);
  const billing = {
    1: 'Stripe',
    2: 'Spreedly',
    4: 'Paddle',
    5: 'Solid',
    6: 'FastSpring',
    7: 'Mollie',
  };

  if (!user) {
    return null;
  }

  let name = user.firstName;
  if (user.lastName) name += ` ${user.lastName}`;
  name = name || 'User';

  const primaryEmail = user.emails.find(e => e.primary);

  const clickHandler = () => setUser(null);

  const newfreeAccessUntil =
    user?.freeAccessUntil === null || user?.freeAccessUntil === 'null'
      ? null
      : user?.freeAccessUntil;
  let oneYearAccessDate = new Date(newfreeAccessUntil);
  oneYearAccessDate = oneYearAccessDate?.setDate(
    oneYearAccessDate.getDate() - 365,
  );

  async function getFreeAccessHandler() {
    if (loading) return;
    const res = window.confirm('Are you sure you want to change access?');
    if (!res) return;
    setLoading(true);
    const accessUntil = new Date();
    accessUntil.setFullYear(accessUntil.getFullYear() + 1);
    const newValue =
      user.freeAccessUntil === null ||
      user.freeAccessUntil === 'null' ||
      user?.freeAccessUntil === undefined
        ? accessUntil
        : null;
    await updateUserField(user.id, 'freeAccessUntil', newValue);
    await getUserById(user.id);
    setLoading(false);
  }

  return (
    <Card
      className={css`
        position: sticky;
        top: 20px;
      `}
    >
      <Card.Header>
        <Card.Header.Title>Current user</Card.Header.Title>
        <Card.Header.Icon onClick={clickHandler}>
          <Delete />
        </Card.Header.Icon>
      </Card.Header>
      {photo && user.photo ? (
        <Card.Image>
          <img
            className={css`
              display: inline-block;
              width: 100px;
              height: 100px;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              margin: 10px 10px;
            `}
            src={user.photo}
            alt="user avatar"
          />
        </Card.Image>
      ) : null}
      <Card.Content
        className={css`
          padding: 0.5em 1em 0.001em 1em;
        `}
      >
        <Title size={5}>{name}</Title>
        {primaryEmail ? (
          <div>
            <b>Email:</b> {primaryEmail.email}
          </div>
        ) : null}
        {!!user?.createdAt && (
          <p>
            <time title={user?.createdAt}>
              <b>User since: </b>{' '}
              {format(new Date(user?.createdAt), 'do MMM yyyy')}
            </time>
          </p>
        )}
        <p>
          <b>Domain:</b> {user.domain}
        </p>
        {!!user?.billingVersion && (
          <p>
            <b>Billing Service:</b> {billing[user.billingVersion]}
          </p>
        )}
        {!!user?.freeAccessUntil && (
          <p>
            One year free access effective from:{' '}
            {format(new Date(oneYearAccessDate), 'do MMM yyyy')}
          </p>
        )}
        {!isSubscriptionDetailLoading &&
          (!!subscription?.cardDetails?.cardBrand || !!user?.cardBrand) && (
            <p>
              <b>Card Brand:</b>{' '}
              {user?.cardBrand || subscription?.cardDetails?.cardBrand}
            </p>
          )}
        {/* {!isSubscriptionDetailLoading &&
          !!subscription?.cardDetails?.cardLastFour && (
            <p>
              <b>Card Num: </b> **** **** ****{' '}
              {subscription?.cardDetails?.cardLastFour}
            </p>
          )} */}
        {(!!user?.cardCountry || !!subscription?.cardDetails?.country) && (
          <p>
            <b>Card Country: </b>
            {user?.cardCountry || subscription?.cardDetails?.country}
          </p>
        )}
        <p>
          <UserRoleTag role={user.role} />
        </p>
        <br></br>
        <div>
          <Button
            color="info"
            disabled={loading}
            onClick={getFreeAccessHandler}
          >
            {user.freeAccessUntil === null ||
            user.freeAccessUntil === 'null' ||
            user?.freeAccessUntil === undefined
              ? 'Grant One Year Free Access'
              : 'Cancel One Year Free Access'}
          </Button>
          {/* {user.subscriptionId && (
            <Column.Group>
              <Column size="two-thirds">
                <InfoPanel />
              </Column>
            </Column.Group>
          )} */}
          <MagicLinkCreator />
          <DeleteAccount />
        </div>
      </Card.Content>
    </Card>
  );
}

UserCard.propTypes = {
  photo: PropTypes.bool,
};

UserCard.defaultProps = {
  photo: true,
};

export default UserCard;
